<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style="height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:1024px; " class="q-my-xl">
      <q-card-section class="row justify-end">
        <q-input
          outlined
          label="Cari Siswa"
          v-model="searchTerm"
          style="max-width:300px"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-card-section>
      <q-card-section horizontal>
        <q-card-section
          class="column q-gutter-md q-pt-none justify-between"
          style="min-width:300px"
        >
          <q-select
            outlined
            v-model="selKelas"
            label="Kelas"
            :options="kelas"
            @input="(siswa = []), getSiswaList()"
          />

          <q-btn
            filled
            v-if="tab == 'catatan'"
            :disable="selKelas == null || siswa.length == 0"
            color="primary"
            label="SIMPAN CATATAN"
            @click="simpan"
          ></q-btn>
          <q-btn
            filled
            v-else-if="tab == 'sikap'"
            :disable="selKelas == null || siswa.length == 0"
            color="primary"
            label="SIMPAN NILAI"
            @click="simpanNilai"
          ></q-btn>
        </q-card-section>
        <q-card-section class="q-pa-none" style="width:100%">
          <q-tabs
            no-caps
            dense
            v-model="tab"
            indicator-color="orange"
            align="justify"
            class="bg-primary text-white"
          >
            <q-tab name="catatan" label="Catatan Walas" />
            <q-tab name="sikap" label="Nilai Sikap" />
          </q-tabs>
          <q-tab-panels v-model="tab">
            <q-tab-panel name="catatan" class="q-pa-none">
              <q-markup-table
                flat
                bordered
                wrap-cells
                dense
                separator="horizontal"
                class="stickyTable bg-indigo-1 full-width bg-grey-4"
                style="height: calc(100vh - 267px);"
              >
                <thead class="bg-indigo-5 text-white text-left">
                  <tr>
                    <th>
                      <q-checkbox
                        v-model="check"
                        @input="checkAll"
                      ></q-checkbox>
                    </th>
                    <th style="width:5%">no</th>
                    <th style="width:10%">NISN</th>
                    <th style="width:30%">nama</th>
                    <th>
                      <div class="row justify-between items-center">
                        <a>Catatan</a>
                        <q-btn
                          flat
                          icon="add"
                          label="INPUT SEMUA"
                          :disable="siswa.length == 0 || isAChecked"
                          @click="confirm = true"
                        ></q-btn>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white">
                  <tr v-if="selKelas == null">
                    <td class="text-center" colspan="5">
                      Silahkan Pilih kelas terlebih dahulu
                    </td>
                  </tr>
                  <tr v-else-if="selKelas != null && siswa.length == 0">
                    <td class="text-center" colspan="5">
                      Tidak Ada Siswa di kelas terpilih
                    </td>
                  </tr>
                  <tr v-for="(val, i) in filSiswa" :key="i">
                    <td>
                      <q-checkbox v-model="val.check"></q-checkbox>
                    </td>
                    <td style="width:5%">{{ i + 1 }}</td>
                    <td style="width:10%">{{ val.nisn }}</td>
                    <td style="width:30%">{{ val.nama }}</td>
                    <td>
                      <q-input
                        bottom-slots
                        dense
                        outlined
                        bg-color="white"
                        v-model="val.catatan"
                        filled
                        autogrow
                        maxlength="250"
                      >
                        <template v-if="val.catatan" v-slot:hint
                          >{{ val.catatan.length }} / 250</template
                        >
                      </q-input>
                    </td>
                  </tr>
                </tbody>
              </q-markup-table>
            </q-tab-panel>
            <q-tab-panel name="sikap" class="q-pa-none">
              <q-markup-table
                flat
                bordered
                separator="cell"
                class="stickyTable bg-indigo-1"
                style="height: calc(100vh - 267px); width:735px"
              >
                <thead class="bg-indigo-5 text-white text-left">
                  <tr>
                    <th rowspan="2">no</th>
                    <th rowspan="2">NISN</th>
                    <th rowspan="2">nama</th>
                    <th colspan="5">Nilai Sosial</th>
                    <th colspan="2">Nilai Spiritual</th>
                  </tr>
                  <tr>
                    <th>Rata-Rata Mapel</th>
                    <th>Walas</th>
                    <th>Pengasuhan</th>
                    <th>Nilai Sosial</th>
                    <th>Predikat</th>
                    <th>Nilai</th>
                    <th>Predikat</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="selKelas == null">
                    <td class="text-center" colspan="10">
                      Silahkan Pilih kelas terlebih dahulu
                    </td>
                  </tr>
                  <tr v-else-if="selKelas != null && siswa.length == 0">
                    <td class="text-center" colspan="10">
                      Tidak Ada Siswa di kelas terpilih
                    </td>
                  </tr>
                  <tr v-for="(val, i) in filSiswa" :key="i">
                    <td style="width:5%">{{ i + 1 }}</td>
                    <td style="width:10%">{{ val.nisn }}</td>
                    <td style="width:30%">{{ val.nama }}</td>
                    <td>{{ val.rerata_mapel }}</td>
                    <template>
                      <q-td class="bg-white">
                        {{ val.nilai_sikap_walas }}
                        <q-popup-edit :value="nilai" title="Masukan Nilai">
                          <q-input
                            type="number"
                            v-model="val.nilai_sikap_walas"
                            dense
                            autofocus
                          />
                        </q-popup-edit>
                      </q-td>
                      <q-td class="bg-white">
                        {{ val.nilai_sikap_pengasuhan }}
                        <q-popup-edit :value="nilai" title="Masukan Nilai">
                          <q-input
                            type="number"
                            v-model="val.nilai_sikap_pengasuhan"
                            dense
                            autofocus
                          />
                        </q-popup-edit>
                      </q-td>
                    </template>
                    <td>{{ val.nilai_sikap }}</td>
                    <td>{{ formatPredikat(val.nilai_sikap) }}</td>
                    <template>
                      <q-td class="bg-white">
                        {{ val.nilai_spiritual }}
                        <q-popup-edit :value="nilai" title="Masukan Nilai">
                          <q-input
                            type="number"
                            v-model="val.nilai_spiritual"
                            dense
                            autofocus
                          />
                        </q-popup-edit>
                      </q-td>
                    </template>
                    <td>{{ formatPredikat(val.nilai_spiritual) }}</td>
                  </tr>
                </tbody>
              </q-markup-table>
            </q-tab-panel>
          </q-tab-panels>
        </q-card-section>
      </q-card-section>
    </q-card>
    <q-dialog v-model="confirm" persistent>
      <q-card style="width:500px">
        <q-card-section>
          <q-input
            bottom-slots
            maxlength="250"
            dense
            outlined
            bg-color="white"
            v-model="catatan"
            autogrow
          >
            <template v-slot:hint>{{ catatan.length }} / 250</template>
          </q-input>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Cancel" color="primary" v-close-popup />
          <q-btn
            label="Masukan"
            color="primary"
            @click="inputsemua()"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      user: {},
      tab: "catatan",
      nilai: null,

      check: false,
      searchTerm: "",
      catatan: "",
      confirm: false,
      kelas: [],
      selKelas: null,
      siswa: [],
      batas: {
        kkm: 75,
        a: 0,
        b: 0,
        c: 0,
        d: 0,
      },
    };
  },
  computed: {
    isAChecked() {
      let checked = false;
      for (let item of this.siswa) {
        if (item.check) {
          checked = true;
        }
      }
      return !checked;
    },
    filSiswa() {
      if (this.searchTerm == "") return this.siswa;
      else {
        return this.siswa.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          let cNISN = val.nisn.indexOf(this.searchTerm) != -1;
          return cNama || cNISN;
        });
      }
    },
  },
  async mounted() {
    await this.getUser();
    await this.getListKelas();
    this.predikatGenerator();
  },
  methods: {
    ...mapGetters(["getCurrentUser"]),
    async getUser() {
      this.user = await this.getCurrentUser();
    },
    checkAll() {
      if (this.check) {
        for (let item of this.siswa) {
          item.check = true;
        }
      } else {
        for (let item of this.siswa) {
          item.check = false;
        }
      }
    },
    async simpanNilai() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http.post(
        `/penilaian/catatan/addnilai/${localStorage.getItem(
          "id_tahun_ajaran"
        )}`,
        this.siswa
      );
      this.$q.loading.hide();
      this.$q.notify({
        message: "Nilai sudah Disimpan",
        color: "positive",
      });
      this.getSiswaList();
    },
    predikatGenerator() {
      let tempBatas = JSON.parse(JSON.stringify(this.batas));
      let patok = 100 - parseFloat(tempBatas.kkm);
      let interval = patok / 3;
      let curNilai = parseFloat(tempBatas.kkm);
      tempBatas.c = curNilai;
      curNilai = curNilai + interval;
      tempBatas.b = Math.floor(curNilai);
      curNilai = curNilai + interval;
      tempBatas.a = Math.floor(curNilai);
      this.batas = tempBatas;
    },
    formatPredikat(nilai) {
      if (nilai == null) {
        return;
      }
      if (nilai < this.batas.kkm) {
        return "D";
      } else if (nilai >= this.batas.kkm && nilai < this.batas.b) {
        return "C";
      } else if (nilai >= this.batas.b && nilai < this.batas.a) {
        return "B";
      } else if (nilai >= this.batas.a && nilai <= 100) {
        return "A";
      }
    },
    async simpan() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .post(
          `/penilaian/catatan/addnote/${localStorage.getItem(
            "id_tahun_ajaran"
          )}`,
          this.siswa
        )
        .then((resp) => {
          this.$q.notify({
            message: "Catatan sudah Disimpan",
            color: "positive",
          });
          this.getSiswaList();
        });
      this.$q.loading.hide();
    },
    inputsemua() {
      let tempSiswa = JSON.parse(JSON.stringify(this.siswa));
      for (let item of tempSiswa) {
        if (item.check) {
          item.catatan = this.catatan;
        }
      }
      this.siswa = tempSiswa;
    },
    async getListKelas() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .get(
          `/penilaian/catatan/getkelas/${this.user.id}/${localStorage.getItem(
            "jenjang"
          )}/${this.user.is_super_user}`
        )
        .then((resp) => {
          this.kelas = resp.data;
        });
      this.$q.loading.hide();
    },
    async getSiswaList() {
      this.$q.loading.show();
      await this.getSiswa();
      // await this.getCatatan();
      await this.getNilaiSikap();
      this.$q.loading.hide();
    },
    async getNilaiSikap() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let tempSiswa = JSON.parse(JSON.stringify(this.siswa));
      let resp = await this.$http.post(
        `/penilaian/catatan/getnilaisikap/${localStorage.getItem(
          "id_tahun_ajaran"
        )}`,
        this.siswa
      );
      for (let i in resp.data) {
        tempSiswa[i].rerata_mapel = parseFloat(resp.data[i]).toFixed(2);
        tempSiswa[i].nilai_sikap = parseFloat(
          (parseFloat(resp.data[i]) +
            parseFloat(tempSiswa[i].nilai_sikap_walas) +
            parseFloat(tempSiswa[i].nilai_sikap_pengasuhan)) /
            3
        ).toFixed(2);
      }
      this.siswa = tempSiswa;
      this.$q.loading.hide();
    },
    async getSiswa() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/penilaian/catatan/getsiswa/${
          this.selKelas.value
        }/${localStorage.getItem("id_tahun_ajaran")}`
      );

      for (let item of resp.data) {
        item.check = false;
      }

      this.siswa = resp.data;
      this.$q.loading.hide();
    },
    async getCatatan() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      await this.$http
        .post("/penilaian/catatan/getnote/", this.siswa)
        .then((resp) => {
          for (let i in resp.data) {
            this.siswa[i].catatan = resp.data[i].catatan;
            this.siswa[i].nilai_spiritual = resp.data[i].nilai_spiritual;
            this.siswa[i].nilai_sikap_walas = resp.data[i].nilai_sikap_walas;
            this.siswa[i].nilai_sikap_pengasuhan =
              resp.data[i].nilai_sikap_pengasuhan;
          }
        });
      this.$q.loading.hide();
    },
  },
};
</script>

<style lang="scss" scoped></style>
